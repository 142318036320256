import React from "react";
import StarRatings from "react-star-ratings";
import { IconCardImage, IconMenu } from "assets/icons";
import GalleryModal from "modals/galleryModal/GalleryModal";
import { hooks } from "common";

function ShopMedia({
  shop,
  handleMenuClick,
  activeIndex,
  setActiveIndex,
  showMenu,
  setShowMenu,
}) {
  const menuImages = shop?.menu_items
    ? shop?.menu_items.filter((item) => item.image_url)
    : [];

  hooks.useCloseModalOnBack(showMenu, setShowMenu);

  return (
    <div className="metaWrap">
      <div className="holder">
        <section className="shopHead">
          <div className="shopImgWrap">
            <img className={!shop?.image_url ? "default" : ""} src={shop?.image_url || IconCardImage} alt=""/>
          </div>
          <div className="shopContent">
            <span className="name">{shop?.name}</span>
            <span className="area">
              {shop?.area?.area}, {shop?.area?.city?.name}
            </span>
            <ul className="rating">
              <StarRatings
                starSpacing="2px"
                starDimension="18px"
                rating={shop?.average_rating != null ? shop?.average_rating : 0}
                starRatedColor="#6C5CE7CC"
                numberOfStars={5}
                name="rating"
              />
            </ul>
          </div>
        </section>
        {menuImages.length > 0 && (
          <a onClick={handleMenuClick} className="menuLink">
            <img src={IconMenu} alt="" />
            <span>
              Menu<span> / Gallery</span>
            </span>
          </a>
        )}
        <GalleryModal
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          images={menuImages}
          show={showMenu}
          setShow={setShowMenu}
          name={shop?.name}
        />
      </div>
    </div>
  );
}

export default ShopMedia;

