import React from "react";
import { Vendor } from "../index";

function VendorsSection({ category, shops }) {
  const hasShops = shops.some((shop) => shop?.category?.name === category?.name);
  return shops && hasShops ? (
    <div className="catWrapper">
      {shops.some((shop) => {
        return shop.category.name === category.name;
      }) && (
        <h2>
          {category.name}{" "}
          <span className="count">
            ({category.shop_count})
          </span>
        </h2>
      )}
      <ul className="catlistWrap">
        {shops.map((shop, index) => {
          return (
            shop?.category?.name === category?.name && (
              <Vendor key={index} shop={shop} />
            )
          );
        })}
      </ul>
    </div>
  ) : null;
}

export default VendorsSection;

